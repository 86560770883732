.fadeIn {
  animation: fadeInAnimation 1s;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeOut {
  animation: fadeOutAnimation 1s;
  display: none !important;
}

@keyframes fadeOutAnimation {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}